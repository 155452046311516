import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { canActivateTeam } from './guards/auth.guard';
import { AuthGuard } from './guards/redirectlogin.guard';
import { canActivateNavItem } from './guards/configuration.guard';

const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children:
    [
      { path:'' , redirectTo:'/auth/login' , pathMatch:'full'},
      {
        path:'auth',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      // {
      //   path:'dashboard',
      //   loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      // }
      {
        path:'Download',
        loadChildren: () => import('./modules/sharedFileLink/sharedfilelink.module').then(m => m.SharedfilelinkModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children:
    [
      {
        path:'viewer', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren: () => import('./modules/file-manager/file-manager.module').then(m => m.FileManagerRoutingModule)
      },
      {
        path:'usermanager', canActivate:[canActivateTeam,AuthGuard,canActivateNavItem],
        loadChildren:()=> import('./modules/usermanager/usermanager.module').then(module => module.UsermanagerModule)
      },
      {
        path:'groups', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/groups/groups.module').then(module => module.GroupsModule)
      },
    ]
  },
  {
    path: "**",
    redirectTo: "auth/not-found",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
