import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cDataService } from 'src/app/services/data.service';
import { cSessionService } from 'src/app/services/session.service';
import { SidebarService } from './sidebar.service';
interface RouteInfo {
  routerLink: string;
  icon: string;
  label: string;
}

interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges{
@Output() onToggleSidenav : EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed: boolean = false;
  public navData: RouteInfo[] = [];
  SidebarList : any =[]
  lConfigurations: any = [];
  screenWidth = 0
  @Input() isSideNavCollapsed;
  userDetails: any;
  constructor(private menuServise: SidebarService,private oDataService : cDataService , private cSessionsService : cSessionService){
    this.userDetails = this.cSessionsService.SessionService_GetUserDetails();
  }
  ngOnInit(): void {
    this.VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole();
    // this.collapsed = false
  }
  ngOnChanges(changes: SimpleChanges): void {
     this.collapsed = this.isSideNavCollapsed;
  }
  VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole()
  {
    var lMenuItems = [];
    this.navData=[];
    this.menuServise.items.subscribe(async menuItems =>{lMenuItems = menuItems;});
    if(this.userDetails.role !== 'admin')
    {
      lMenuItems = lMenuItems.filter(element => element.label !== 'Admin');
    }
    this.navData=lMenuItems
  }
  SidebarComponent_CustomizingtheSideBar(oIncomingObject)
  {
    let dataBaseObject : any = {database : false,bucket:false};
    dataBaseObject.database = oIncomingObject.database?oIncomingObject.database:false;
    dataBaseObject.bucket = oIncomingObject.bucket?oIncomingObject.bucket:false;
    if(dataBaseObject?.bucket == true && dataBaseObject?.database == false)
    {
      this.SidebarList = [];
      this.navData.forEach(element => {if(element.label == 'Viewer')this.SidebarList.push(element)});
    }
    else if(dataBaseObject?.database == true && dataBaseObject?.bucket == false)
    {
      this.SidebarList = [];
      this.navData.forEach(element => {if(element.label != 'Viewer')this.SidebarList.push(element)});
    }
    else if(dataBaseObject?.bucket == true && dataBaseObject?.database == true)
    {
      this.SidebarList = [];
      this.navData.forEach(element => {this.SidebarList.push(element)})
    }
  }
  SidebarComponent_CloseSidenav()
  {
    this.collapsed = false
    this.onToggleSidenav.emit({collapsed:this.collapsed, screenWidth:this.screenWidth});

  }
  SidebarComponent_CloseSidebar()
 {
  this.SidebarComponent_CloseSidenav()
  
 }
}
