import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  constructor(private titleService: Title) {}

  updateTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }
}
