import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDropdown, NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cDataService } from 'src/app/services/data.service';
import { MustMatch } from 'src/app/services/must-match.validator';
import { cSessionService } from 'src/app/services/session.service';
import {Location} from '@angular/common';
import { UiService } from 'src/app/services/ui.service';
import { environment } from 'src/environments/environment';
interface TenantInformation {
  tenantId: string;
  tenantName: string;
}
interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
@Output() onToggleSidenav : EventEmitter<SideNavToggle> = new EventEmitter();
  @Input() isSideNavCollapsed:any;
  @Output() emitSidenavCollapsed = new EventEmitter()
  
  bShowIcon = false;
  bShowSettingDropdown = false;
  UserDetails : any;
  bShowSearchIcon = false;
  searchResult : string = "";
  searchDocumentResult : string = "";
  showTrashLabel: boolean =false;
  bDisplayErrorBlock:boolean = false;
  changePasswordForm:FormGroup
  changeNameForm:FormGroup;
  loginTenantInformation : TenantInformation = {tenantId : "",tenantName : "",};
  resMessage: { message: any; responseType: any; color: any; };
  closesidebar: any;
  bSplitArea: boolean;
  bDisplayGlobalSearches: boolean =false;
  bDisplayTenantInformation: boolean =false;
  selectedDocumentFolderId: string= "";
  searchWithQNA : boolean = false;
  versionInformation: any;
  public logo: string = "";
  showLogo: boolean=false;
  showEllipses: boolean=false;
  collapsed: boolean= false;
  screenWidth: number;
  userDetails: any;
  environment: any;
  bToggleFilterDropdown: boolean =false;
  constructor(private fb: FormBuilder,config: NgbDropdownConfig,private _location: Location, private cSessionsService:cSessionService,private sUIService:UiService, private oRoute : Router ,private oFormBuilder:FormBuilder, private oDataService : cDataService,private oSessionService : cSessionService,private oModalService : NgbModal){
    this.environment = environment.Theme
    config.autoClose = true;
  }
  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
  ngOnInit(): void {
    this.oDataService.bDisplyDropdown.subscribe(res => {
     this.bShowIcon = res;
    })
    this.oDataService.bDisplaySettingDropdown.subscribe(res => {
      this.bShowSettingDropdown = res;
     })

     this.oDataService.bShowHeaderSearch.subscribe(res => {
      this.bShowSearchIcon = res;
     })
     this.oDataService.trash.subscribe(value=>{
      this.showTrashLabel=value
    })
     this.UserDetails = this.oSessionService.SessionService_GetUserDetails();

     this.oDataService.closeSidebar.subscribe(res =>{
      this.closesidebar = res
     })
     this.oDataService.bDisplayDocEllipses.subscribe(res =>{
      this.showEllipses = res
     })
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.userDetails = this.cSessionsService.SessionService_GetUserDetails();
    if(this.userDetails?.role =='admin') this.collapsed = this.isSideNavCollapsed;
  }
  preventInput(event: KeyboardEvent): void {
    event.preventDefault();  // Prevents typing in the input field
}

  HeaderComponent_OpenEditprofile(editProfile){
    this.oModalService.open(editProfile,  { centered: true ,backdrop : 'static', size: 'md' });
    this.HeaderComponent_InitializeChangeNameForm();
  }
  HeaderComponent_ChangePassword(changePassword){
    this.oModalService.open(changePassword,  { centered: true ,backdrop : 'static', size: 'md' });
    this.HeaderComponent_InitializeChangePasswordForm();
  }
  HeaderComponent_DismissModal(){
    this.changePasswordForm?.reset();
    this,this.changeNameForm?.reset();
    this.oModalService.dismissAll();
  }
  HeaderComponent_Logout()
  {
    this.oDataService.oGetFolders.next('');
    this.oDataService.oGetDocFolders.next('');
    localStorage.clear();
    this.oRoute.navigate(['/auth/login']);
   
  }
  HeaderComponent_hideHeaderSearch(){
    this.oDataService.globalSearch.next({folderId:'', searchBoolan : false});
    localStorage.removeItem('searchQuery')
    this.searchDocumentResult=''
    this.searchWithQNA=false
    this.collapsed = false
    this.onToggleSidenav.emit({collapsed:this.collapsed, screenWidth:this.screenWidth});
   
  }
  HeaderComponent_InitializeChangePasswordForm()
  {
    const oPasswordRejex = /^.{8,}$/;
    this.changePasswordForm = this.oFormBuilder.group({
      userId : [this.UserDetails.userId, Validators.required],
      password: ['', [Validators.required, Validators.pattern(oPasswordRejex)]],
      rptPassword: ['', Validators.required],
      oldPassword: ['', [Validators.required, Validators.pattern(oPasswordRejex)]],
    },
    {validator: MustMatch('password', 'rptPassword')}
    )
  }
  HeaderComponent_InitializeChangeNameForm()
  {
    let oTextRejex = /^(?! )(.*)$/;
    this.changeNameForm = this.oFormBuilder.group({
      userId : [this.UserDetails.userId, [Validators.required]],
      firstname: [this.UserDetails.firstName, [Validators.required, Validators.pattern(oTextRejex)]],
      lastname: [this.UserDetails.lastName, [Validators.required, Validators.pattern(oTextRejex)]]
    }
    )
  }
  HeaderComponent_changePassword(){
    if(this.changePasswordForm.valid)
    {
      let body=this.UserDetails
      body.oldPassword=this.changePasswordForm.controls['oldPassword'].value
      body.password=this.changePasswordForm.controls['password'].value
      body.rptPassword=this.changePasswordForm.controls['rptPassword'].value
      this.oDataService.DataService_ChangePassword(body).subscribe({
      next: (result) => {
       if(result.failed)
       {
        this.HeaderComponent_displayAlertMessage(result.message,'failed','danger');
       }
       else
       {
        this.oModalService.dismissAll();
        this.changePasswordForm.reset();
        this.HeaderComponent_displayAlertMessage('Success','success','success');
        setTimeout(() => {
          this.HeaderComponent_Logout()
        }, 2000);
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message=='null'?'error':error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
    }
    else
    {
      this.changePasswordForm.markAllAsTouched()
    }
  }
  HeaderComponent_changeName(){
    if(this.changeNameForm.valid)
    {
      let body=JSON.parse(JSON.stringify(this.UserDetails))
      body.firstName=this.changeNameForm.controls['firstname'].value
      body.lastName=this.changeNameForm.controls['lastname'].value
      body.fullName=body.firstName+" "+body.lastName
      this.oDataService.DataService_EditUserProfile(body).subscribe({
      next: (result) => {
       if(result.failed)
       {
        if(result?.namedMessages)this.HeaderComponent_displayAlertMessage(result.namedMessages[0].message,'failed','danger');
        else{this.HeaderComponent_displayAlertMessage(result.detailMessage,'failed','danger');}
       }
       else
       {
        this.oModalService.dismissAll();
        this.changeNameForm.reset();
        this.HeaderComponent_displayAlertMessage('Success','success','success');
        this.UserDetails = result.props.user
        this.oSessionService.SessionService_SetUserDetails(result.props.user)
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
    }
    else{
      this.changeNameForm.markAllAsTouched()
    }
  }
  // -------------------- ALERT MESSAGES --------------------
  HeaderComponent_displayAlertMessage(sIncommingMessage, sIncommingResponseType,sIncommingColor) {
    this.bDisplayErrorBlock = true
    this.resMessage = 
    {
      message: sIncommingMessage,
      responseType : sIncommingResponseType,
      color :  sIncommingColor
    };
    setTimeout(() => { this.bDisplayErrorBlock = false; }, 3000);
  }
  HeaderComponent_GoTopreviousPage()
  {
      this.oRoute.navigate(['/viewer'])
     
  }
  HeaderComponent_ToggleCollapse()
  {
    this.bSplitArea = !this.bSplitArea;
  }
  HeaderComponent_TenantInformation(oIncomingModal)
  {
    this.oDataService.DataServiceGetTenantInformation().subscribe({
      next: (result) => {
       if(result.failed)
       {
        this.HeaderComponent_displayAlertMessage( result.message,'failed','danger');
       }
       else
       {
         this.loginTenantInformation = result;
         this.oModalService.open(oIncomingModal,  { centered: true ,backdrop : 'static', size: 'md' });
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
  }
  HeaderComponent_VersionInformation(oIncomingModal)
  {
    this.oDataService.DataService_GetWBVersion().subscribe({
      next: (result) => {
       if(result.failed)
       {
        this.HeaderComponent_displayAlertMessage( result.message,'failed','danger');
       }
       else
       {
         this.versionInformation =  this.removeSpacesInKeys(result);
         const apiDateTime = new Date(this.versionInformation.BuildDate);
         // Format the date portion
        const formattedDate = apiDateTime.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        });

        // Format the time portion in 12-hour format with AM/PM
        const formattedTime = apiDateTime.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true, // Use 12-hour format with AM/PM
        });
         const formattedDateTime = `${formattedDate} ${formattedTime}`;
         this.versionInformation.BuildDate = formattedDateTime
         this.oModalService.open(oIncomingModal,  { centered: true ,backdrop : 'static', size: 'md' });
       }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage( error.message,'failed','danger');
      },
      complete: () => { },
    }
    )
  }
  removeSpacesInKeys(obj) {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.replace(/ /g, ''); 
        newObj[newKey] = obj[key];
      }
    }
    return newObj;
  }
  SidebarComponent_ToggleCollapse()
  {
    this.collapsed = !this.collapsed
    this.onToggleSidenav.emit({collapsed:this.collapsed, screenWidth:this.screenWidth});

  }

  toggleDropdown()
  {
    this.bToggleFilterDropdown = !this.bToggleFilterDropdown
  }
}
