export const navbarData = [
    {
        routerLink: '/viewer',
        icon:'fa-eye',
        label: 'Viewer'
    },
    {
        routerLink: '/groups',
        icon:'fa-sharp fa-solid fa-people-group',
        label: 'Groups'
    },
    {
        routerLink: '/usermanager',
        icon:'fa-user',
        label: 'Admin'
    }
    
]