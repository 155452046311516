import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "[appHorizontalScroll]",
})
export class HorizontalScrollDirective {
  constructor(private el: ElementRef) {}

  @HostListener("wheel", ["$event"])
  onWheel(event: WheelEvent): void {
    const scrollContainer = this.el.nativeElement.parentElement;
    if (event.shiftKey) {
      event.preventDefault();
      // Adjust the scrollLeft based on the wheel delta
      scrollContainer.scrollLeft += event.deltaY;
    }
  }

  scrollToRightEnd(): void {
    const scrollContainer = this.el.nativeElement.parentElement;
    scrollContainer.scrollTo({
      left: scrollContainer.scrollWidth - scrollContainer.clientWidth,
      behavior: 'smooth'
    });
  }
}