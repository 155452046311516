import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgbDatepicker, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './loader/loader.component';
import { AlertMessagesComponent } from './alert-messages/alert-messages.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClickOutsideDirective } from './header/click-outside.directive';


@NgModule({
  declarations: [
    HeaderComponent,SidebarComponent,
    LoaderComponent,
    AlertMessagesComponent,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    RouterModule,
    NgbModule
  ],
  exports: [HeaderComponent, SidebarComponent,LoaderComponent,AlertMessagesComponent]
})
export class SharedModule { }
